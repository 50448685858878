/*=========================
	All css
==========================*/
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../plugins/fontawesome/css/font-awesome.min.css");
@import url("../plugins/themify/themify-icons.css");
@import url("../plugins/owl-carousel/owl.carousel.css");
@import url("../plugins/bootstrap-select/bootstrap-select.min.css");
@import url("../plugins/magnific-popup/magnific-popup.css");
@import url("../plugins/scroll/scrollbar.css");
@import url("../plugins/lightgallery/css/lightgallery.min.css");